<template>
  <div class="header-container">
    <h1>{{content.page.title}}</h1>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <img src="@/assets/img/jury/kaposvari_anna.jpg" :alt="content.name_1"/>
      </div>
      <div class="col-md-8">
        <h2>{{content.name_1}}</h2>
        <span class="position">{{content.position_1}}</span>
        <div class="desc" v-html="content.desc_1"></div>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/jury/csombordi_robert_peter.jpg" :alt="content.name_2"/>
      </div>
      <div class="col-md-8">
        <h2>{{content.name_2}}</h2>
        <span class="position">{{content.position_2}}</span>
        <div class="desc" v-html="content.desc_2"></div>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/jury/tuskes_istvan.jpg" :alt="content.name_3"/>
      </div>
      <div class="col-md-8">
        <h2>{{content.name_3}}</h2>
        <span class="position">{{content.position_3}}</span>
        <div class="desc" v-html="content.desc_3"></div>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/jury/galsine_szikszai_agnes.jpg" :alt="content.name_4"/>
      </div>
      <div class="col-md-8">
        <h2>{{content.name_4}}</h2>
        <span class="position">{{content.position_4}}</span>
        <div class="desc" v-html="content.desc_4"></div>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/jury/zornanszki_tamas.jpg" :alt="content.name_5"/>
      </div>
      <div class="col-md-8">
        <h2>{{content.name_5}}</h2>
        <span class="position">{{content.position_5}}</span>
        <div class="desc" v-html="content.desc_5"></div>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/jury/matrai_norbert.jpg" :alt="content.name_6"/>
      </div>
      <div class="col-md-8">
        <h2>{{content.name_6}}</h2>
        <span class="position">{{content.position_6}}</span>
        <div class="desc" v-html="content.desc_6"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  name:'Competition',
  props:['initData','contents','pageId'],
  data(){

    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
.col-md-4{
  margin-top: 80px;
}
.col-md-4 img{
  max-width: 100%;
  height: auto;
}
.col-md-8{
  margin-top: 80px;
  padding-left: 85px;
}
.col-md-8 h2{
  margin-top: 0!important;
}
.col-md-8 span{
  color: var(--dark-gray);
}
div {
  margin: 0 auto;
  padding: 0;
}



.container {
  max-width: 1064px;
  margin-bottom: 7rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9rem;
  background-image: linear-gradient(to right, rgba(119, 132, 16, .8), rgba(119, 132, 16, .8)), url("@/assets/img/background.png");
  mix-blend-mode: normal;
}

.header-container h1 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 300;
}

.container:deep(h2) {
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3rem;
  margin-top: 5rem;

}

.container:deep(p) {
  margin: 2.5rem 0;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
}

.container:deep(.legend) {
  background-color: var(--gray);
  width: 100%;
  padding: 1.5rem;
}

.container:deep(.legend p) {
  margin: 0;
  padding: 0;
}

.container:deep(.accent) {
  color: var(--dark-green);
  font-weight: 400;
}

.container:deep(ul) {
  margin-top: 2rem;
  margin-bottom: 3rem;
  line-height: 2.5rem;
}

.container:deep(h3) {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 2.75rem;
}

.container:deep(table) {
  width: 100%;
  border-collapse: collapse;
}

.container:deep(table thead th) {
  border-bottom: 1px solid var(--dark-gray);
}

.container:deep(table thead tr th) {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2rem;

  letter-spacing: 1px;
}

.container:deep(table tbody td) {
  border-bottom: 1px solid var(--gray);
}

.container:deep(table tbody tr td) {
  font-style: normal;
  font-weight: 300;
  line-height: 3rem;
}

@media (max-width: 768px){
  .col-md-8{
    margin-top: 40px;
    padding-left: 0;
  }
  .container {
    padding: 0 1rem;
  }
}
</style>